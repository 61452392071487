/* $bgColor1: #3856a6; //58, 85, 166 */ // original 
$bgColor1: rgb(245,46,34);
$bgButtonColor: rgb(245,46,34);
$bgButtonColor2: rgba(245,46,34,0.7);
$color1: rgb(245,171,234);
$color2: rgb(189,242,248);
/* colors from @Elle */
/* #F52E22 */
/* (245,46,34) */
/* #F5ABEA */
/* (245,171,234) */
/* #BDF2F8 */
/* (189,242,248) */

/* colors from email? */
    /* teal      #159264 */
    /* red       #970c10  or  #992a1b */
    /* brown  #8fd4b */
    /* beige   #c2b19   or  #e9dccc */
    /* light grey */

