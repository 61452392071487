/* Colville-tit */

@import 'src/Config/Colville-tit/colors';


// $footerImage: url(/images/footer.png);
$headerColor: rgba(255,157,100,1);
$spacer1Color: rgba(255,157,100,0);
$spacer2Color: rgba(255,157,100,1);
$spacer3Color: rgba(255,157,100,0);
$spacer4Color: rgba(255,157,100,1);

/* $menuColor: rgba(110,61,21,0.7); */
$menuColor: $bgButtonColor2;
$activeTabColor: $bgButtonColor;

$htmlColor: rgba(0,0,0,0.05);
$AppColor: rgba(0,0,0,0.05);


.clientConfig-footerStyle {
  background-image: url(/images/footer.png);
  // background-size: contain;
  // background-position: center;
};

.button {
  background-color: $bgColor1 !important;
}
